import React from 'react';
import { OurInsightsOverviewViewContext, TemplateProps } from '../../types';
import { OurInsightsOverviewView } from '../../views';
import { PathService } from '../../services';

const OurInsightsOverview: React.FunctionComponent<TemplateProps<
  OurInsightsOverviewViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurInsightsOverviewView
      blogs={JSON.parse(pageContext.blogs)}
      whitepapers={JSON.parse(pageContext.whitepapers)}
    />
  );
};

export default OurInsightsOverview;
